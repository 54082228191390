body {
    margin: 0;
}

.flex-container {
    height: calc(100% - 150px);
    width: 100%;
    /* margin: 0 auto; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.container {
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    max-height: 100vh;
    padding: 0%;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.vol-video-container {
    width: 60%;
    float: left;
    position: relative;
    height: 100%;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
}
.div-send-msg {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.btn-down-vol {
    z-index: 2;
    position: fixed; 
    bottom: 0px;
    width: 60%;
    height: auto;
}
.btn-down-vol 
.MuiTabs-flexContainer{
    justify-content: space-evenly;
}