html, body {
  	height: 100%;
}
body {
	margin: 0;
	text-align: center;
	background-color: whitesmoke;
}

.container2 {
	padding-top: 50px;
	margin: auto;
	align-items: center;
	justify-content: center;
	text-align: center;
}